import { setStore, getStore } from "@/utils/store.js";

export default {
  state: {
    accessToken: getStore({ name: "accessToken" }) || "",
    userInfo: getStore({ name: "userInfo" }) || {},
  },
  actions: {},
  mutations: {
    SET_ACCESS_TOKEN: (state, accessToken) => {
      state.accessToken = accessToken;
      setStore({
        name: "accessToken",
        content: state.accessToken,
      });
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo;
      setStore({
        name: "userInfo",
        content: userInfo,
      });
    },
  },
};
