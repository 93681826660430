import { validatenull } from "./validate.js";
import moment from "moment";

/**
 * 把字符串化的函数还原为可执行函数
 * 请确保传入的字符串可信任
 */
export function stringParseToFunction(str) {
  // eslint-disable-next-line no-new-func
  return new Function('"use strict"; return (' + str + ")")();
}

/**
 * 通过指定值查找对应数据
 */
export function findTreeItemById(dataList, value, props = { key: "id", children: "children" }) {
  if (!Array.isArray(dataList) || (!value && value !== 0)) {
    throw new Error("数据格式错误");
  }
  for (const item of dataList) {
    if (item[props.key] === value) {
      return item;
    }
    if (Array.isArray(item[props.children]) && item[props.children].length !== 0) {
      const menuItem = findTreeItemById(item.children, value, props);
      if (menuItem) {
        return menuItem;
      }
    }
  }
}

/**
 * 动态插入css
 */
export function loadStyle(url) {
  const link = document.createElement("link");
  link.type = "text/css";
  link.rel = "stylesheet";
  link.href = url;
  const head = document.getElementsByTagName("head")[0];
  head.appendChild(link);
}

/**
 * 判断路由是否相等
 */
export function diff(obj1, obj2) {
  delete obj1.close;
  var o1 = obj1 instanceof Object;
  var o2 = obj2 instanceof Object;
  if (!o1 || !o2) {
    return obj1 === obj2;
  }
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }
  for (var attr in obj1) {
    var t1 = obj1[attr] instanceof Object;
    var t2 = obj2[attr] instanceof Object;
    if (t1 && t2) {
      return diff(obj1[attr], obj2[attr]);
    } else if (obj1[attr] !== obj2[attr]) {
      return false;
    }
  }
  return true;
}

/**
 * 生成随机len位数字
 */
export function randomLenNum(len, date) {
  let random = "";
  random = Math.ceil(Math.random() * 100000000000000)
    .toString()
    .substr(0, len || 4);
  if (date) random = random + Date.now();
  return random;
}

/**
 * 对象序列化
 */
export function serialize(data) {
  const list = [];
  Object.keys(data).forEach((ele) => {
    list.push(`${ele}=${data[ele]}`);
  });
  return list.join("&");
}

/**
 * 获取数据类型
 */
export function getObjType(obj) {
  var toString = Object.prototype.toString;
  var map = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object",
  };
  if (obj instanceof Element) {
    return "element";
  }
  return map[toString.call(obj)];
}

// 清空数据值
export function clearVal(obj, list = []) {
  if (!obj) return {};
  Object.keys(obj).forEach((ele) => {
    if (!list.includes(ele) && !validatenull(obj[ele])) {
      const type = getObjType(obj[ele]);
      if (type === "array") obj[ele] = [];
      else if (type === "object") obj[ele] = {};
      else if (["number", "boolean", "string"].includes(type)) obj[ele] = undefined;
      else obj[ele] = "";
    }
  });
  return obj;
}

/**
 * 浏览器判断是否全屏
 */
export function fullscreenToggel() {
  if (fullscreenEnable()) {
    exitFullScreen();
  } else {
    reqFullScreen();
  }
}

/**
 * esc监听全屏
 */
export function listenfullscreen(callback) {
  function listen() {
    callback();
  }

  document.addEventListener("fullscreenchange", function () {
    listen();
  });
  document.addEventListener("mozfullscreenchange", function () {
    listen();
  });
  document.addEventListener("webkitfullscreenchange", function () {
    listen();
  });
  document.addEventListener("msfullscreenchange", function () {
    listen();
  });
}

/**
 * 浏览器判断是否全屏
 */
export function fullscreenEnable() {
  return document.isFullScreen || document.mozIsFullScreen || document.webkitIsFullScreen;
}

/**
 * 浏览器全屏
 */
export function reqFullScreen() {
  if (document.documentElement.requestFullScreen) {
    document.documentElement.requestFullScreen();
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.documentElement.webkitRequestFullScreen();
  } else if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen();
  }
}

/**
 * 浏览器退出全屏
 */
export function exitFullScreen() {
  if (document.documentElement.requestFullScreen) {
    document.exitFullScreen();
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.documentElement.mozRequestFullScreen) {
    document.mozCancelFullScreen();
  }
}

/**
 *获取url指定参数
 */
export function getParams() {
  let search = window.location.search.replace(/^\?/, "");
  let pairs = search.split("&");
  let paramsMap = pairs
    .map((pair) => {
      let [key, value] = pair.split("=");
      return [decodeURIComponent(key), decodeURIComponent(value)];
    })
    .reduce((res, [key, value]) => Object.assign(res, { [key]: value }), {});
  return paramsMap;
}

export function getHashQueryString() {
  const query = window.location.hash.split("?")[1];
  const pairs = query.split("&");
  let paramsMap = pairs
    .map((pair) => {
      let [key, value] = pair.split("=");
      return [decodeURIComponent(key), decodeURIComponent(value)];
    })
    .reduce((res, [key, value]) => Object.assign(res, { [key]: value }), {});
  return paramsMap;
}

/**
 * 对象数组去重
 * @param {Array} arr 对象数组
 * @param {String} logo 对象数组中需要去重的标识  如[{id:1,name:'aa'},{id:1,name:'11'}]中 logo可以为'id'或'name'

 */
export function unique(arr, logo) {
  const res = new Map();
  return arr.filter((arr) => !res.has(arr[logo]) && res.set(arr[logo], 1));
}

export function getTheHour(date) {
  if (!date) {
    date = new Date().getTime();
  }
  let ts = 1000 * 60 * 60;
  const d = new Date(Math.floor(new Date(date).getTime() / ts) * ts);
  return moment(d).format("YYYY-MM-DD HH:mm:ss");
}

export function appendQuery(originUrl, key, value) {
  const url = originUrl.split("?")[0];
  let querys = [];
  if (originUrl.split("?")[1] && originUrl.split("?")[1].split) {
    querys = originUrl.split("?")[1].split("&");
  }
  // 没传参
  if (!Array.isArray(querys)) {
    return `${url}?${key}=${value}`;
  }
  querys.push(`${key}=${value}`);
  return `${url}?${querys.join("&")}`;
}
