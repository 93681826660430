import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import user from "@/store/module/user";
import common from "@/store/module/common";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters,
  mutations: {},
  actions: {},
  modules: {
    user,
    common,
  },
});
