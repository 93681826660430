export default {
  title: "山海记官网", // 网站标题(项目名称)
  storageKey: "zhoushan-emergency-h5", // 可设置为项目英文名 避免不同项目在同一环境下缓存互相串联
  indexPage: "", // 项目首页地址
  indexTitle: "山海记", // 项目首页菜单上方显示的名称
  logo: `${process.env.BASE_URL}logo-white.png`, // 项目或企业LOGO
  defaultAvatar: `${process.env.BASE_URL}images/default-avatar.jpg`, // 默认用户头像
  iv: "smartlink@12345@", // 加密偏移值
  fistPage: {
    label: "仪表盘",
    value: "/wel/index",
    params: {},
    query: {},
    close: false,
    meta: {
      isTab: true,
    },
  },
  menu: {
    iconDefault: "iconfont icon-caidan",
    props: {
      label: "menuName",
      path: "path",
      icon: "icon",
      children: "children",
      meta: "meta",
    },
  },
};
