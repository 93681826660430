import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import componentRegister from "@/componentRegister";
import Vant from "vant";
import "vant/lib/index.css";
import "./assets/css/vantUI.scss";

Vue.config.productionTip = false;
Vue.use(componentRegister);
Vue.use(Vant);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
