import { setStore, getStore } from "@/utils/store.js";

export default {
  state: {
    subsystemAppidList: getStore("appIds") || [],
  },
  actions: {},
  mutations: {
    SET_APP_IDS(state, data) {
      setStore("appIds", data);
      state.subsystemAppidList = data;
    },
  },
};
